


import { Card, Grid2, IconButton, Stack, Typography } from "@mui/material";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ITask, ITaskList } from "../views/app/task_manager";
import { useTheme } from "@mui/system";
import Task from "./task";
const TaskList = ({ list, tasks, updateTask }: { list: ITaskList, tasks: ITask[], updateTask: (task: ITask) => void }) => {
    const theme = useTheme();
    return <>
        <Card style={{ background: `linear-gradient(20deg, ${theme.palette.primary.main}, ${list.color})`, borderRadius: 2, position: 'sticky', top: 0, color: "white", zIndex: 1 }}>
            <Typography variant="h5" style={{  }}>
                {list.name}
            </Typography>
        </Card>      
        <Stack spacing={1}>
            {tasks.map((task, index) => (
                <Task key={index} task={task} updateTask={updateTask} />
            ))}
        </Stack>
        </>
    }

export default TaskList;
