/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleDTO
 */
export interface RoleDTO {
    /**
     * 
     * @type {number}
     * @memberof RoleDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RoleDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDTO
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof RoleDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof RoleDTO
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof RoleDTO
     */
    deletedAt: Date | null;
}

/**
 * Check if a given object implements the RoleDTO interface.
 */
export function instanceOfRoleDTO(value: object): value is RoleDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('deletedAt' in value) || value['deletedAt'] === undefined) return false;
    return true;
}

export function RoleDTOFromJSON(json: any): RoleDTO {
    return RoleDTOFromJSONTyped(json, false);
}

export function RoleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'deletedAt': (json['deleted_at'] == null ? null : new Date(json['deleted_at'])),
    };
}

export function RoleDTOToJSON(value?: RoleDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'description': value['description'],
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
        'deleted_at': (value['deletedAt'] == null ? null : (value['deletedAt'] as any).toISOString()),
    };
}

