import { enqueueSnackbar } from "notistack";
import { UsersApi } from "./api/apis/UsersApi";
import { Token } from "./api/models/Token";
import { Configuration, ConfigurationParameters } from "./api/runtime";
import { MailApi, SocialRentingApi } from "./api/apis";



export class APIWrapper {
    config: ConfigurationParameters;
    
    constructor() {
        console.log(process.env.REACT_APP_ENVIRONMENT)
        console.log(process.env)
        this.config = {
            basePath: process.env.REACT_APP_ENVIRONMENT !== 'production' ? 'http://localhost:8000' : 'https://api.slievaart.com',
        }
        // Get token from local storage
        const token : string | null = localStorage.getItem('token');
        if (!token) {
            console.warn('No token found in local storage');
            return;
        }
        const tokenObject: Token = JSON.parse(token);
        console.log('Token', tokenObject);
        
        this.config.accessToken = `Bearer ${tokenObject.accessToken}`;

        this.config.middleware = [
            {
                post : async (context) => {
                    if (context.response.status === 401) {
                        console.log('Token expired, logging out');
                        localStorage.removeItem('token');
                        window.location.href = '/login';
                    }
                    if(context.response.status == 403){
                        enqueueSnackbar('You do not have permission to complete this action', {variant: 'error'});
                    }
                }
            }
        ]

    }

    public getUsersAPI() {
        return new UsersApi(new Configuration(this.config));

    }

    public getMailAPI() {
        return new MailApi(new Configuration(this.config));
    }

    public getSocialRentingAPI() {
        return new SocialRentingApi(new Configuration(this.config));
    }
        
        
}
