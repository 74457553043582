/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Portal } from './Portal';
import {
    PortalFromJSON,
    PortalFromJSONTyped,
    PortalToJSON,
} from './Portal';

/**
 * 
 * @export
 * @interface SocialRentingLoginDTO
 */
export interface SocialRentingLoginDTO {
    /**
     * 
     * @type {number}
     * @memberof SocialRentingLoginDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SocialRentingLoginDTO
     */
    username: string;
    /**
     * 
     * @type {Portal}
     * @memberof SocialRentingLoginDTO
     */
    portal: Portal;
    /**
     * 
     * @type {Date}
     * @memberof SocialRentingLoginDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SocialRentingLoginDTO
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SocialRentingLoginDTO
     */
    deletedAt: Date | null;
}

/**
 * Check if a given object implements the SocialRentingLoginDTO interface.
 */
export function instanceOfSocialRentingLoginDTO(value: object): value is SocialRentingLoginDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('portal' in value) || value['portal'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('deletedAt' in value) || value['deletedAt'] === undefined) return false;
    return true;
}

export function SocialRentingLoginDTOFromJSON(json: any): SocialRentingLoginDTO {
    return SocialRentingLoginDTOFromJSONTyped(json, false);
}

export function SocialRentingLoginDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialRentingLoginDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'portal': PortalFromJSON(json['portal']),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'deletedAt': (json['deleted_at'] == null ? null : new Date(json['deleted_at'])),
    };
}

export function SocialRentingLoginDTOToJSON(value?: SocialRentingLoginDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'username': value['username'],
        'portal': PortalToJSON(value['portal']),
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
        'deleted_at': (value['deletedAt'] == null ? null : (value['deletedAt'] as any).toISOString()),
    };
}

