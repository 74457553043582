/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
  SocialRentingLoginDTO,
  SocialRentingLoginInDTO,
} from '../models/index';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    SocialRentingLoginDTOFromJSON,
    SocialRentingLoginDTOToJSON,
    SocialRentingLoginInDTOFromJSON,
    SocialRentingLoginInDTOToJSON,
} from '../models/index';

export interface CreateLoginsRequest {
    socialRentingLoginInDTO: SocialRentingLoginInDTO;
}

export interface DeleteLoginsRequest {
    id: number;
}

export interface GetAllLoginsRequest {
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetCountLoginsRequest {
    filter?: string;
}

export interface GetOneLoginsRequest {
    id: number;
}

export interface RespondToLotteryRequest {
    username: string;
}

export interface UpdateLoginsRequest {
    id: number;
    socialRentingLoginInDTO: SocialRentingLoginInDTO;
}

/**
 * 
 */
export class SocialRentingApi extends runtime.BaseAPI {

    /**
     * Create (Logins)
     */
    async createLoginsRaw(requestParameters: CreateLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialRentingLoginDTO>> {
        if (requestParameters['socialRentingLoginInDTO'] == null) {
            throw new runtime.RequiredError(
                'socialRentingLoginInDTO',
                'Required parameter "socialRentingLoginInDTO" was null or undefined when calling createLogins().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/social_renting/logins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SocialRentingLoginInDTOToJSON(requestParameters['socialRentingLoginInDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialRentingLoginDTOFromJSON(jsonValue));
    }

    /**
     * Create (Logins)
     */
    async createLogins(requestParameters: CreateLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialRentingLoginDTO> {
        const response = await this.createLoginsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete (Logins)
     */
    async deleteLoginsRaw(requestParameters: DeleteLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteLogins().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/social_renting/logins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete (Logins)
     */
    async deleteLogins(requestParameters: DeleteLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteLoginsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (Logins)
     */
    async getAllLoginsRaw(requestParameters: GetAllLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SocialRentingLoginDTO>>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/social_renting/logins/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SocialRentingLoginDTOFromJSON));
    }

    /**
     * Get All (Logins)
     */
    async getAllLogins(requestParameters: GetAllLoginsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SocialRentingLoginDTO>> {
        const response = await this.getAllLoginsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (Logins)
     */
    async getCountLoginsRaw(requestParameters: GetCountLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/social_renting/logins/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (Logins)
     */
    async getCountLogins(requestParameters: GetCountLoginsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountLoginsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get One (Logins)
     */
    async getOneLoginsRaw(requestParameters: GetOneLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialRentingLoginDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOneLogins().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/social_renting/logins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialRentingLoginDTOFromJSON(jsonValue));
    }

    /**
     * Get One (Logins)
     */
    async getOneLogins(requestParameters: GetOneLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialRentingLoginDTO> {
        const response = await this.getOneLoginsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Respond To Lottery
     */
    async respondToLotteryRaw(requestParameters: RespondToLotteryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling respondToLottery().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/social_renting/respond/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters['username']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Respond To Lottery
     */
    async respondToLottery(requestParameters: RespondToLotteryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.respondToLotteryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update (Logins)
     */
    async updateLoginsRaw(requestParameters: UpdateLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SocialRentingLoginDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLogins().'
            );
        }

        if (requestParameters['socialRentingLoginInDTO'] == null) {
            throw new runtime.RequiredError(
                'socialRentingLoginInDTO',
                'Required parameter "socialRentingLoginInDTO" was null or undefined when calling updateLogins().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/social_renting/logins/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SocialRentingLoginInDTOToJSON(requestParameters['socialRentingLoginInDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SocialRentingLoginDTOFromJSON(jsonValue));
    }

    /**
     * Update (Logins)
     */
    async updateLogins(requestParameters: UpdateLoginsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SocialRentingLoginDTO> {
        const response = await this.updateLoginsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
