import { createTheme } from "@mui/material";

const baseTheme = createTheme({
    palette: {
        primary: {
            main: "#1976d2",
        },
        secondary: {
            main: "#dc004e",
        },
        background: {
            default: "#f2f2f2",
            paper: "#ffffff",
        },
    },
    typography: {
        fontFamily: "Verdana",
        fontWeightRegular: 400,
        h4: {
            fontWeight: 700,
        },
    },
});

const theme = createTheme(baseTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-containedPrimary': {
                        backgroundImage: `linear-gradient(45deg, ${baseTheme.palette.primary.main}, ${baseTheme.palette.secondary.main})`,
                        '&:hover': {
                            backgroundImage: `linear-gradient(45deg, ${baseTheme.palette.secondary.light}, ${baseTheme.palette.primary.light})`,
                        },
                    },
                    '&.MuiButton-outlinedPrimary': {
                        
                        borderImage: `linear-gradient(45deg, ${baseTheme.palette.secondary.main},${baseTheme.palette.primary.main}) 1`,
                        '&:hover': {
                            backgroundImage: `linear-gradient(45deg, ${baseTheme.palette.secondary.light}, ${baseTheme.palette.primary.light})`,
                            color: baseTheme.palette.common.white,
                        },
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: baseTheme.spacing(2),
                    borderRadius: 2,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.secondary.main,
                    textDecoration: 'underline',
                    '&:hover': {
                        color: baseTheme.palette.secondary.dark,
                        cursor: 'pointer',
                    },
                },
            },
        },
    },
});

export default theme;
