/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    mailAddress: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    isActive: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserDTO
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserDTO
     */
    deletedAt: Date | null;
}

/**
 * Check if a given object implements the UserDTO interface.
 */
export function instanceOfUserDTO(value: object): value is UserDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('mailAddress' in value) || value['mailAddress'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('deletedAt' in value) || value['deletedAt'] === undefined) return false;
    return true;
}

export function UserDTOFromJSON(json: any): UserDTO {
    return UserDTOFromJSONTyped(json, false);
}

export function UserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'mailAddress': json['mail_address'],
        'isActive': json['is_active'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'deletedAt': (json['deleted_at'] == null ? null : new Date(json['deleted_at'])),
    };
}

export function UserDTOToJSON(value?: UserDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'mail_address': value['mailAddress'],
        'is_active': value['isActive'],
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
        'deleted_at': (value['deletedAt'] == null ? null : (value['deletedAt'] as any).toISOString()),
    };
}

