/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailTemplateDTO
 */
export interface MailTemplateDTO {
    /**
     * 
     * @type {number}
     * @memberof MailTemplateDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateDTO
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateDTO
     */
    body: string;
    /**
     * 
     * @type {Date}
     * @memberof MailTemplateDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MailTemplateDTO
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MailTemplateDTO
     */
    deletedAt: Date | null;
}

/**
 * Check if a given object implements the MailTemplateDTO interface.
 */
export function instanceOfMailTemplateDTO(value: object): value is MailTemplateDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('subject' in value) || value['subject'] === undefined) return false;
    if (!('body' in value) || value['body'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('deletedAt' in value) || value['deletedAt'] === undefined) return false;
    return true;
}

export function MailTemplateDTOFromJSON(json: any): MailTemplateDTO {
    return MailTemplateDTOFromJSONTyped(json, false);
}

export function MailTemplateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailTemplateDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'subject': json['subject'],
        'body': json['body'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'deletedAt': (json['deleted_at'] == null ? null : new Date(json['deleted_at'])),
    };
}

export function MailTemplateDTOToJSON(value?: MailTemplateDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'subject': value['subject'],
        'body': value['body'],
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
        'deleted_at': (value['deletedAt'] == null ? null : (value['deletedAt'] as any).toISOString()),
    };
}

