


import { Card, Grid2, IconButton, Stack, Typography } from "@mui/material";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ITask, ITaskList } from "../views/app/task_manager";
import { useTheme } from "@mui/system";
const Task = ({task, updateTask}: {task: ITask, updateTask: (task: ITask) => void}) => {
    const theme = useTheme();
    const handleTaskCompletion = () => {
        task.completed = !task.completed;
        updateTask(task);
    }

    const datePassed = new Date(task.due_date) < new Date();
    const dateToday = new Date().toDateString() === new Date(task.due_date).toDateString();
    let color = datePassed ? theme.palette.error.main : theme.palette.grey[500];
    if (dateToday) {
        color = theme.palette.success.light;
    }

    return <Grid2 key={task.name} container justifyContent="flex-start" spacing={1}>
    <Grid2 size={12} container alignItems="flex-start">
        <IconButton sx={{ padding: 0, marginTop: 1 }} onClick={() => handleTaskCompletion()}>
            {task.completed ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        </IconButton>
        <Grid2>
            <Typography paddingTop={1}>{task.name}</Typography>
            <Stack >
                <Typography color={color}>
                    Due: {new Date(task.due_date).toLocaleString('en-EN' , { weekday: 'long', month: 'numeric', day: 'numeric', year: 'numeric', hour: '2-digit', minute: 'numeric', hour12: false })}
                </Typography>
            {task.description && <Typography color={theme.palette.grey[500]}>{task.description}</Typography>}
            </Stack>
        </Grid2>
    </Grid2>
</Grid2>
}

export default Task;
