// Page that is always loaded and determines which page to show based on the URL

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './home';
import LoginView from './login';
import theme from '../config/mui_theme';
import { ThemeProvider } from '@mui/material';
import Dashboard from './app/dashboard';
import TaskManager from './app/task_manager';
import Settings from './app/settings';
function AppRouter() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
    <Routes>
      <Route path="/app"  Component={Dashboard} />
      <Route path="/app/task_manager" Component={TaskManager} />
      <Route path="/app/settings" Component={Settings} />
      <Route path="/login" Component={LoginView} />
    </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default AppRouter;
