import React from 'react';
import AppRouter from './views/router';
import { ThemeProvider } from '@mui/material';
import theme from './config/mui_theme';
import { SnackbarProvider } from 'notistack';
function App() {
  
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <SnackbarProvider />
      <AppRouter />
      </ThemeProvider>
    </div>
  );
}
export default App;
