import { Box, Card, Grid2, Typography, useTheme } from '@mui/material';
import MainLayout from './main_layout';

function Dashboard() {
    return <>
    <MainLayout title="Dashboard">
        <Typography> Welcome to the dashboard! This is where you are able to find all the information you need. 

        </Typography>
        <Grid2 container spacing={2}>
            {["Test", "Test", "Test"].map((item, index) => (
                <Grid2 key={index} size={4}>
                    <Card sx={{ padding: 2 }}>
                        <Typography>{item}</Typography>
                    </Card>
                </Grid2>
            ))}
        </Grid2>
        </MainLayout> 
    </>
}

export default Dashboard;;
