/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Portal } from './Portal';
import {
    PortalFromJSON,
    PortalFromJSONTyped,
    PortalToJSON,
} from './Portal';

/**
 * 
 * @export
 * @interface SocialRentingLoginInDTO
 */
export interface SocialRentingLoginInDTO {
    /**
     * 
     * @type {string}
     * @memberof SocialRentingLoginInDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof SocialRentingLoginInDTO
     */
    password: string;
    /**
     * 
     * @type {Portal}
     * @memberof SocialRentingLoginInDTO
     */
    portal: Portal;
}

/**
 * Check if a given object implements the SocialRentingLoginInDTO interface.
 */
export function instanceOfSocialRentingLoginInDTO(value: object): value is SocialRentingLoginInDTO {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('portal' in value) || value['portal'] === undefined) return false;
    return true;
}

export function SocialRentingLoginInDTOFromJSON(json: any): SocialRentingLoginInDTO {
    return SocialRentingLoginInDTOFromJSONTyped(json, false);
}

export function SocialRentingLoginInDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialRentingLoginInDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
        'portal': PortalFromJSON(json['portal']),
    };
}

export function SocialRentingLoginInDTOToJSON(value?: SocialRentingLoginInDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
        'password': value['password'],
        'portal': PortalToJSON(value['portal']),
    };
}

