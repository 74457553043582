

import { Box, Button, Card, Grid2, Icon, Link, Stack, Typography, useTheme, Tabs, Tab } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import React, { createContext, ReactElement, useContext, useState } from 'react';
const main_menu = [
    { name: 'Dashboard', icon: DashboardIcon, path: '/app' },
    { name: 'Task Manager', icon: AssignmentTurnedInIcon, path: '/app/task_manager' },
    { name: 'Settings', icon: DashboardIcon, path: '/app/settings' },

]

interface ITab {
    name: string;
    onClick?: Function;
}

function MainLayout({ children, title }: { children?: React.ReactNode, title?: string }) {

    const currentUrl = window.location.pathname;
    const currentMenuTab = main_menu.find((item) => item.path === currentUrl);

    const theme = useTheme();

    // Filter the MainLayoutSubNavigation component from the children
    const subNavigation = React.Children.toArray(children).find((child: any) => child.type === MainLayoutSubNavigation) as ReactElement;

    // Filter the children that are not MainLayoutSubNavigation
    const otherChildren = React.Children.toArray(children).filter((child: any) => child.type !== MainLayoutSubNavigation);
    

    return <Grid2 container justifyContent="center" spacing={2}>
        <Grid2 size={12}>
            <Box sx={{ minHeight: "6vh", boxShadow: 1 }} >
                <Grid2 container justifyContent="flex-start" alignItems="center" style={{ height: '100%' }} spacing={2} padding={1}>
                    <Grid2 >
                        <img src="/icon_colors.png" alt="logo" style={{ height: '6vh' }} />
                    </Grid2>
                    {main_menu.map((item, index) => (
                        <Grid2 key={index}>
                            <Button
                                component={Link}
                                href={item.path}
                                startIcon={<Icon component={item.icon} />}
                                sx={{ color: currentMenuTab === item ? theme.palette.secondary.main : theme.palette.grey[700]
                                    , fontSize: 25, textTransform: 'none' }}
                            >
                                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                    {item.name}
                                </Box>    </Button>
                        </Grid2>))}
                </Grid2>
            </Box>
        </Grid2>
        <Grid2 size={10}>
            <>

                <Stack spacing={1} marginTop={2}>
                    <Card sx={{
                        borderRadius: 2, background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`, paddingBottom: subNavigation ? 0 : 2
                    }}>
                        
                        <Grid2 container justifyContent="space-between" alignItems="center">
                            
                            <Typography variant="h4" color="white">
                                {title}
                            </Typography>
                        </Grid2>
                        {subNavigation && subNavigation}
                    </Card>
                    {otherChildren}
                </Stack>
            </>
        </Grid2>
    </Grid2>

}

export function MainLayoutSubNavigation({ children }: { children?: React.ReactElement[] }) {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <Box sx={{}}>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: 'white' } }}
            >
                {React.Children.map(children, (child, index) => (
                    React.cloneElement(child as ReactElement, {
                        index,
                        selectedTab,
                        setSelectedTab,
                    })
                ))}
            </Tabs>
        </Box>
    );
}

export function MainLayoutSubNavigationItem({ name, onClick, href, index, selectedTab, setSelectedTab }: { name: string, onClick?: Function, href?: string, index?: number, selectedTab?: number, setSelectedTab?: Function }) {
    const handleClick = (event: React.SyntheticEvent) => {
        if (onClick) onClick(name);
        if (setSelectedTab) setSelectedTab(index);
    };

    return (
        <Tab
            label={name}
            onClick={handleClick}
            component={Link}
            href={href}
            sx={{
                color: 'white',
                opacity: selectedTab === index ? '100%' : '50%',
                '&:hover': {
                    color: 'white',
                    opacity: '100%',
                },
            }}
        />
    );
}

export default MainLayout;
