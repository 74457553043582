/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailDTO
 */
export interface MailDTO {
    /**
     * 
     * @type {number}
     * @memberof MailDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MailDTO
     */
    recipient: string;
    /**
     * 
     * @type {number}
     * @memberof MailDTO
     */
    templateId: number;
    /**
     * 
     * @type {Date}
     * @memberof MailDTO
     */
    sentAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MailDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MailDTO
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof MailDTO
     */
    deletedAt: Date | null;
}

/**
 * Check if a given object implements the MailDTO interface.
 */
export function instanceOfMailDTO(value: object): value is MailDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('recipient' in value) || value['recipient'] === undefined) return false;
    if (!('templateId' in value) || value['templateId'] === undefined) return false;
    if (!('sentAt' in value) || value['sentAt'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('deletedAt' in value) || value['deletedAt'] === undefined) return false;
    return true;
}

export function MailDTOFromJSON(json: any): MailDTO {
    return MailDTOFromJSONTyped(json, false);
}

export function MailDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'recipient': json['recipient'],
        'templateId': json['template_id'],
        'sentAt': (new Date(json['sent_at'])),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'deletedAt': (json['deleted_at'] == null ? null : new Date(json['deleted_at'])),
    };
}

export function MailDTOToJSON(value?: MailDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'recipient': value['recipient'],
        'template_id': value['templateId'],
        'sent_at': ((value['sentAt']).toISOString()),
        'created_at': ((value['createdAt']).toISOString()),
        'updated_at': ((value['updatedAt']).toISOString()),
        'deleted_at': (value['deletedAt'] == null ? null : (value['deletedAt'] as any).toISOString()),
    };
}

