import { Box,Stack, Button, Card, Grid2, TextField, Typography, useTheme } from '@mui/material';
import CalendarScheduling from '../components/calendar_scheduling';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { APIWrapper } from '../api';
import { Token } from '../api/models/Token';

function LoginView() {
    const navigate = useNavigate();
    const handleLogin = () => {
        console.log('Login clicked');    
        const userApi = new APIWrapper().getUsersAPI();
        userApi.loginForAccessToken({username, password}).then((response : Token) => {
            console.log('Login response', response);
            localStorage.setItem('token', JSON.stringify(response));
            navigate('/app');
        })
        .catch((error) => {
            console.error('Error logging in', error);
        });
    }
    const theme = useTheme();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const gradientStyle = {
        background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
        height: {xs:"30vh", md: '100vh'},
        width: '100%',
        
    };

    
    return <> 
    <Grid2 container >
    <Grid2 size={{xs:12, md:6}}>
        
        <Box sx={gradientStyle} >
            <Grid2 container justifyContent="center" alignItems="center" sx={{ height: {xs:'100%', md:'100vh'}}}>
                    <img src="/icon_colors.svg" alt="logo" style={{  maxHeight: '80%', maxWidth:"80%" }} />
            </Grid2>
                
        </Box>
    </Grid2>
    <Grid2 size={{xs:12, md:6}}>
        <Grid2 container justifyContent="center" alignItems="center" sx={{ height: {xs: '50vh', md: '100vh'} }}>

        <Box sx={{ margin: '10%', padding: '5%', width: "90%" }}>
            <Stack spacing={4}>
                <div>
                <Typography variant="h4" align="center">Welcome back!</Typography>
                <Typography variant="body1" align="center">Please enter your username and password</Typography>
                </div>
                <TextField label="Username" fullWidth onChange={(e) => setUsername(e.target.value)} />
                <TextField label="Password" fullWidth type="password" onChange={(e) => setPassword(e.target.value)} />
                <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
                    Login</Button>
            </Stack>
            </Box>
        </Grid2>
    </Grid2>
    </Grid2>
    </>
}

export default LoginView;
