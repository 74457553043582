/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
  PermissionDTO,
  PermissionInDTO,
  RoleDTO,
  RoleInDTO,
  Token,
  UserDTO,
  UserInAdminDTO,
  UserInDTO,
} from '../models/index';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    PermissionDTOFromJSON,
    PermissionDTOToJSON,
    PermissionInDTOFromJSON,
    PermissionInDTOToJSON,
    RoleDTOFromJSON,
    RoleDTOToJSON,
    RoleInDTOFromJSON,
    RoleInDTOToJSON,
    TokenFromJSON,
    TokenToJSON,
    UserDTOFromJSON,
    UserDTOToJSON,
    UserInAdminDTOFromJSON,
    UserInAdminDTOToJSON,
    UserInDTOFromJSON,
    UserInDTOToJSON,
} from '../models/index';

export interface CreatePermissionRequest {
    permissionInDTO: PermissionInDTO;
}

export interface CreateRoleRequest {
    roleInDTO: RoleInDTO;
}

export interface CreateUserRequest {
    userInAdminDTO: UserInAdminDTO;
}

export interface CreateUserNonAdminRequest {
    userInDTO: UserInDTO;
}

export interface DeletePermissionRequest {
    id: number;
}

export interface DeleteRoleRequest {
    id: number;
}

export interface DeleteUserRequest {
    id: number;
}

export interface GetAllPermissionRequest {
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetAllPermissionRolesRequest {
    id: number;
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetAllRoleRequest {
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetAllRolePermissionsRequest {
    id: number;
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetAllRoleUsersRequest {
    id: number;
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetAllUserRequest {
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetAllUserRolesRequest {
    id: number;
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetCountPermissionRequest {
    filter?: string;
}

export interface GetCountPermissionRolesRequest {
    id: number;
    filter?: string;
}

export interface GetCountRoleRequest {
    filter?: string;
}

export interface GetCountRolePermissionsRequest {
    id: number;
    filter?: string;
}

export interface GetCountRoleUsersRequest {
    id: number;
    filter?: string;
}

export interface GetCountUserRequest {
    filter?: string;
}

export interface GetCountUserRolesRequest {
    id: number;
    filter?: string;
}

export interface GetOnePermissionRequest {
    id: number;
}

export interface GetOneRoleRequest {
    id: number;
}

export interface GetOneUserRequest {
    id: number;
}

export interface LoginForAccessTokenRequest {
    username: string;
    password: string;
    grantType?: string | null;
    scope?: string;
    clientId?: string | null;
    clientSecret?: string | null;
}

export interface UpdatePermissionRequest {
    id: number;
    permissionInDTO: PermissionInDTO;
}

export interface UpdatePermissionRolesRequest {
    id: number;
    relationId: number;
    active?: boolean;
}

export interface UpdateRoleRequest {
    id: number;
    roleInDTO: RoleInDTO;
}

export interface UpdateRolePermissionsRequest {
    id: number;
    relationId: number;
    active?: boolean;
}

export interface UpdateRoleUsersRequest {
    id: number;
    relationId: number;
    active?: boolean;
}

export interface UpdateUserRequest {
    id: number;
    userInAdminDTO: UserInAdminDTO;
}

export interface UpdateUserRolesRequest {
    id: number;
    relationId: number;
    active?: boolean;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Create (Permission)
     */
    async createPermissionRaw(requestParameters: CreatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionDTO>> {
        if (requestParameters['permissionInDTO'] == null) {
            throw new runtime.RequiredError(
                'permissionInDTO',
                'Required parameter "permissionInDTO" was null or undefined when calling createPermission().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/permission`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionInDTOToJSON(requestParameters['permissionInDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDTOFromJSON(jsonValue));
    }

    /**
     * Create (Permission)
     */
    async createPermission(requestParameters: CreatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionDTO> {
        const response = await this.createPermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create (Role)
     */
    async createRoleRaw(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleDTO>> {
        if (requestParameters['roleInDTO'] == null) {
            throw new runtime.RequiredError(
                'roleInDTO',
                'Required parameter "roleInDTO" was null or undefined when calling createRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleInDTOToJSON(requestParameters['roleInDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDTOFromJSON(jsonValue));
    }

    /**
     * Create (Role)
     */
    async createRole(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleDTO> {
        const response = await this.createRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create (User)
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters['userInAdminDTO'] == null) {
            throw new runtime.RequiredError(
                'userInAdminDTO',
                'Required parameter "userInAdminDTO" was null or undefined when calling createUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInAdminDTOToJSON(requestParameters['userInAdminDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     * Create (User)
     */
    async createUser(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create User Non Admin
     */
    async createUserNonAdminRaw(requestParameters: CreateUserNonAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters['userInDTO'] == null) {
            throw new runtime.RequiredError(
                'userInDTO',
                'Required parameter "userInDTO" was null or undefined when calling createUserNonAdmin().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/user/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInDTOToJSON(requestParameters['userInDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     * Create User Non Admin
     */
    async createUserNonAdmin(requestParameters: CreateUserNonAdminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.createUserNonAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete (Permission)
     */
    async deletePermissionRaw(requestParameters: DeletePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deletePermission().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/permission/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete (Permission)
     */
    async deletePermission(requestParameters: DeletePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deletePermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete (Role)
     */
    async deleteRoleRaw(requestParameters: DeleteRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete (Role)
     */
    async deleteRole(requestParameters: DeleteRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete (User)
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete (User)
     */
    async deleteUser(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (Permission)
     */
    async getAllPermissionRaw(requestParameters: GetAllPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PermissionDTO>>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/permission/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PermissionDTOFromJSON));
    }

    /**
     * Get All (Permission)
     */
    async getAllPermission(requestParameters: GetAllPermissionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PermissionDTO>> {
        const response = await this.getAllPermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (Permission.Roles)
     */
    async getAllPermissionRolesRaw(requestParameters: GetAllPermissionRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleDTO>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAllPermissionRoles().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/permission/{id}/roles/all`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleDTOFromJSON));
    }

    /**
     * Get All (Permission.Roles)
     */
    async getAllPermissionRoles(requestParameters: GetAllPermissionRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleDTO>> {
        const response = await this.getAllPermissionRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (Role)
     */
    async getAllRoleRaw(requestParameters: GetAllRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleDTO>>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleDTOFromJSON));
    }

    /**
     * Get All (Role)
     */
    async getAllRole(requestParameters: GetAllRoleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleDTO>> {
        const response = await this.getAllRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (Role.Permissions)
     */
    async getAllRolePermissionsRaw(requestParameters: GetAllRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PermissionDTO>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAllRolePermissions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/{id}/permissions/all`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PermissionDTOFromJSON));
    }

    /**
     * Get All (Role.Permissions)
     */
    async getAllRolePermissions(requestParameters: GetAllRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PermissionDTO>> {
        const response = await this.getAllRolePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (Role.Users)
     */
    async getAllRoleUsersRaw(requestParameters: GetAllRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDTO>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAllRoleUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/{id}/users/all`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDTOFromJSON));
    }

    /**
     * Get All (Role.Users)
     */
    async getAllRoleUsers(requestParameters: GetAllRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDTO>> {
        const response = await this.getAllRoleUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (User)
     */
    async getAllUserRaw(requestParameters: GetAllUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserDTO>>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/user/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDTOFromJSON));
    }

    /**
     * Get All (User)
     */
    async getAllUser(requestParameters: GetAllUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserDTO>> {
        const response = await this.getAllUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (User.Roles)
     */
    async getAllUserRolesRaw(requestParameters: GetAllUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoleDTO>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAllUserRoles().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/user/{id}/roles/all`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleDTOFromJSON));
    }

    /**
     * Get All (User.Roles)
     */
    async getAllUserRoles(requestParameters: GetAllUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoleDTO>> {
        const response = await this.getAllUserRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (Permission)
     */
    async getCountPermissionRaw(requestParameters: GetCountPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/permission/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (Permission)
     */
    async getCountPermission(requestParameters: GetCountPermissionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountPermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (Permission.Roles)
     */
    async getCountPermissionRolesRaw(requestParameters: GetCountPermissionRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCountPermissionRoles().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/permission/{id}/roles/count`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (Permission.Roles)
     */
    async getCountPermissionRoles(requestParameters: GetCountPermissionRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountPermissionRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (Role)
     */
    async getCountRoleRaw(requestParameters: GetCountRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (Role)
     */
    async getCountRole(requestParameters: GetCountRoleRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (Role.Permissions)
     */
    async getCountRolePermissionsRaw(requestParameters: GetCountRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCountRolePermissions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/{id}/permissions/count`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (Role.Permissions)
     */
    async getCountRolePermissions(requestParameters: GetCountRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountRolePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (Role.Users)
     */
    async getCountRoleUsersRaw(requestParameters: GetCountRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCountRoleUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/{id}/users/count`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (Role.Users)
     */
    async getCountRoleUsers(requestParameters: GetCountRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountRoleUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (User)
     */
    async getCountUserRaw(requestParameters: GetCountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/user/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (User)
     */
    async getCountUser(requestParameters: GetCountUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (User.Roles)
     */
    async getCountUserRolesRaw(requestParameters: GetCountUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCountUserRoles().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/user/{id}/roles/count`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (User.Roles)
     */
    async getCountUserRoles(requestParameters: GetCountUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountUserRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get One (Permission)
     */
    async getOnePermissionRaw(requestParameters: GetOnePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOnePermission().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/permission/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDTOFromJSON(jsonValue));
    }

    /**
     * Get One (Permission)
     */
    async getOnePermission(requestParameters: GetOnePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionDTO> {
        const response = await this.getOnePermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get One (Role)
     */
    async getOneRoleRaw(requestParameters: GetOneRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOneRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDTOFromJSON(jsonValue));
    }

    /**
     * Get One (Role)
     */
    async getOneRole(requestParameters: GetOneRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleDTO> {
        const response = await this.getOneRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get One (User)
     */
    async getOneUserRaw(requestParameters: GetOneUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOneUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     * Get One (User)
     */
    async getOneUser(requestParameters: GetOneUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.getOneUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Login For Access Token
     */
    async loginForAccessTokenRaw(requestParameters: LoginForAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling loginForAccessToken().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling loginForAccessToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['grantType'] != null) {
            formParams.append('grant_type', requestParameters['grantType'] as any);
        }

        if (requestParameters['username'] != null) {
            formParams.append('username', requestParameters['username'] as any);
        }

        if (requestParameters['password'] != null) {
            formParams.append('password', requestParameters['password'] as any);
        }

        if (requestParameters['scope'] != null) {
            formParams.append('scope', requestParameters['scope'] as any);
        }

        if (requestParameters['clientId'] != null) {
            formParams.append('client_id', requestParameters['clientId'] as any);
        }

        if (requestParameters['clientSecret'] != null) {
            formParams.append('client_secret', requestParameters['clientSecret'] as any);
        }

        const response = await this.request({
            path: `/users/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Login For Access Token
     */
    async loginForAccessToken(requestParameters: LoginForAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.loginForAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Read Users Me
     */
    async readUsersMeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     * Read Users Me
     */
    async readUsersMe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.readUsersMeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update (Permission)
     */
    async updatePermissionRaw(requestParameters: UpdatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PermissionDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePermission().'
            );
        }

        if (requestParameters['permissionInDTO'] == null) {
            throw new runtime.RequiredError(
                'permissionInDTO',
                'Required parameter "permissionInDTO" was null or undefined when calling updatePermission().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/permission/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionInDTOToJSON(requestParameters['permissionInDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PermissionDTOFromJSON(jsonValue));
    }

    /**
     * Update (Permission)
     */
    async updatePermission(requestParameters: UpdatePermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PermissionDTO> {
        const response = await this.updatePermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update (Permission.Roles)
     */
    async updatePermissionRolesRaw(requestParameters: UpdatePermissionRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updatePermissionRoles().'
            );
        }

        if (requestParameters['relationId'] == null) {
            throw new runtime.RequiredError(
                'relationId',
                'Required parameter "relationId" was null or undefined when calling updatePermissionRoles().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['active'] != null) {
            queryParameters['active'] = requestParameters['active'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/permission/{id}/roles/{relation_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"relation_id"}}`, encodeURIComponent(String(requestParameters['relationId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update (Permission.Roles)
     */
    async updatePermissionRoles(requestParameters: UpdatePermissionRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updatePermissionRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update (Role)
     */
    async updateRoleRaw(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateRole().'
            );
        }

        if (requestParameters['roleInDTO'] == null) {
            throw new runtime.RequiredError(
                'roleInDTO',
                'Required parameter "roleInDTO" was null or undefined when calling updateRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RoleInDTOToJSON(requestParameters['roleInDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDTOFromJSON(jsonValue));
    }

    /**
     * Update (Role)
     */
    async updateRole(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleDTO> {
        const response = await this.updateRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update (Role.Permissions)
     */
    async updateRolePermissionsRaw(requestParameters: UpdateRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateRolePermissions().'
            );
        }

        if (requestParameters['relationId'] == null) {
            throw new runtime.RequiredError(
                'relationId',
                'Required parameter "relationId" was null or undefined when calling updateRolePermissions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['active'] != null) {
            queryParameters['active'] = requestParameters['active'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/{id}/permissions/{relation_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"relation_id"}}`, encodeURIComponent(String(requestParameters['relationId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update (Role.Permissions)
     */
    async updateRolePermissions(requestParameters: UpdateRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateRolePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update (Role.Users)
     */
    async updateRoleUsersRaw(requestParameters: UpdateRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateRoleUsers().'
            );
        }

        if (requestParameters['relationId'] == null) {
            throw new runtime.RequiredError(
                'relationId',
                'Required parameter "relationId" was null or undefined when calling updateRoleUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['active'] != null) {
            queryParameters['active'] = requestParameters['active'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/role/{id}/users/{relation_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"relation_id"}}`, encodeURIComponent(String(requestParameters['relationId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update (Role.Users)
     */
    async updateRoleUsers(requestParameters: UpdateRoleUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateRoleUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update (User)
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateUser().'
            );
        }

        if (requestParameters['userInAdminDTO'] == null) {
            throw new runtime.RequiredError(
                'userInAdminDTO',
                'Required parameter "userInAdminDTO" was null or undefined when calling updateUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserInAdminDTOToJSON(requestParameters['userInAdminDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     * Update (User)
     */
    async updateUser(requestParameters: UpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update (User.Roles)
     */
    async updateUserRolesRaw(requestParameters: UpdateUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateUserRoles().'
            );
        }

        if (requestParameters['relationId'] == null) {
            throw new runtime.RequiredError(
                'relationId',
                'Required parameter "relationId" was null or undefined when calling updateUserRoles().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['active'] != null) {
            queryParameters['active'] = requestParameters['active'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/users/user/{id}/roles/{relation_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"relation_id"}}`, encodeURIComponent(String(requestParameters['relationId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update (User.Roles)
     */
    async updateUserRoles(requestParameters: UpdateUserRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateUserRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
