import { Box, Button, Card, Divider, Grid2, IconButton, Link, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import MainLayout from './main_layout';
import { useEffect, useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ChecklistIcon from '@mui/icons-material/Checklist';
import moment, { Moment } from 'moment';
import TaskList from '../../components/task_list';
import Task from '../../components/task';
export interface ITaskList {
    name: string;
    color: string;
}

export interface ITask {
    name: string;
    description: string;
    due_date: Date;
    completed: boolean;
    list: ITaskList;
}

function TaskManager() {
    const [tasks, setTasks]: [ITask[], Function] = useState([]);
    const [taskLists, setTaskLists]: [ITaskList[], Function] = useState([]);
    const theme = useTheme();
    const [tab, setTab] = useState(0);
    const [distinctTaskDays, setDistinctTaskDays] = useState<Moment[]>([]);

    useEffect(() => {
        // Mock data
        const getRandomDateInFuture = () => {
            const now = moment();
            const days = Math.floor(Math.random() * 14);
            return now.add(days, 'days').toDate();
        }

        const getRandomColor = () => {
            const colors = [theme.palette.primary.main, theme.palette.secondary.main, theme.palette.error.main, theme.palette.warning.main, theme.palette.info.main, theme.palette.success.main];
            return colors[Math.floor(Math.random() * colors.length)];
        }

        const randomTaskLists = ['Personal', 'Work', 'Shopping', 'School', 'Home'];

        const randomTasks = ["Buy groceries", "Finish project", "Call mom", "Pick up kids", "Go to gym", "Study for exam", "Clean house", "Cook dinner", "Walk dog", "Water plants", "Pay bills", "Schedule appointment", "Buy birthday gift", "Return library books", "Do laundry", "Take out trash", "Mow lawn", "Fix leaky faucet", "Change lightbulb", "Organize closet", "Vacuum house", "Wash car", "Clean gutters", "Paint fence", "Plant flowers", "Trim hedges", "Rake leaves", "Shovel snow", "Clean garage", "Organize basement", "Clean attic", "Clean gutters", "Paint fence", "Plant flowers", "Trim hedges", "Rake leaves", "Shovel snow", "Clean garage", "Organize basement", "Clean attic"];

        // Loop over randomTasks and create a task for each
        const newTasks = randomTasks.map((task) => {
            return {
                name: task,
                description: 'A description to aid in the completion of the task',
                due_date: getRandomDateInFuture(),
                completed: false,
                list: { name: randomTaskLists[Math.floor(Math.random() * randomTaskLists.length)], color: getRandomColor() }
            }
        });
        
        // Sort tasks by due date
        newTasks.sort((a, b) => moment(a.due_date).diff(moment(b.due_date)));

        setTasks(newTasks);

        // Create a list of unique task lists
        const uniqueTaskLists = newTasks.map((task) => task.list.name).filter((value, index, self) => self.indexOf(value) === index);
        const taskListsWithColors = uniqueTaskLists.map((list) => {
            return { name: list, color: getRandomColor() };
        });
        setTaskLists(taskListsWithColors);
    }
        , []);



    useEffect(() => {
        const distinctDays = tasks
            .map((task) => moment(task.due_date).startOf('day'))
            .filter((value, index, self) => self.findIndex((m) => m.isSame(value, 'day')) === index);
        setDistinctTaskDays(distinctDays);
    }, [tasks]);

    const updateTask = (task: ITask) => {
        const newTasks = tasks.map((t) => {
            if (t === task) {
                return task;
            }
            return t;
        });
        setTasks(newTasks);
    }

    return <>
        <MainLayout title="Task Manager">

            <Typography> Welcome to the task manager! This is where you are able to find all the tasks you need to complete.

            </Typography>
            <Grid2 container spacing={2}>
                <Grid2 size={{xs:12, md:4}}>
                    <Stack spacing={2}>
                    <Card>
                        <Stack spacing={1}>
                            <Button variant="contained">Create new task</Button>
                            <Grid2 container spacing={1}>
                                <Grid2 size={{xs:12, md: 6}}>
                                <Button variant="outlined" fullWidth>Create new task list</Button>
                                </Grid2>
                                <Grid2 size={{xs:12, md:6}}>
                                <Button variant="outlined" fullWidth>Edit task list</Button>
                                </Grid2>
                                </Grid2>
                        </Stack>
                    </Card>
                    <Card >
                        <Typography variant="h5" >All task lists</Typography>
                        <Stack spacing={1}>
                            {taskLists.map((list, index) => (
                                <Link key={index} onClick={() => setTab(index)} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <ChecklistIcon sx={{ fontSize: 20, marginRight: 1 }} />
                                    <Typography>
                                        {list.name}
                                    </Typography>
                                </Link>

                            ))}
                        </Stack>
                    </Card>
                    
                    
                    </Stack>
                </Grid2>
                <Grid2 size={{xs:12, md:8}}>
                    <Card>
                        <Typography variant="h5"> Upcoming tasks</Typography>
                        <Stack spacing={1}>
                            {distinctTaskDays
                                .filter((day) => day.isSameOrBefore(moment().add(7, 'days'), 'day'))
                                .map((day, index) => (
                                    <>
                                        <Divider />
                                        <Typography key={index}>{day.format('dddd, MMMM Do YYYY')}</Typography>
                                        {tasks.filter((task) => moment(task.due_date).startOf('day').isSame(day)).map((task, index) => (
                                            <Task key={index} task={task} updateTask={updateTask} />
                                        ))}
                                    </>
                                ))}

                        </Stack>
                    </Card>
                </Grid2>
                {taskLists.map((list, index) => (
                    <Grid2 key={index} size={{ xs: 12, md: 4 }}>
                    <Card sx={{ maxHeight: {xs:'100vh',md: '40vh'},  minHeight: {md:'40vh'}, overflowY: 'auto' }}>
                        <TaskList list={list} tasks={tasks.filter((task) => task.list.name === list.name)} updateTask={updateTask} />
                        </Card>
                    </Grid2>
                ))}
        </Grid2>
    </MainLayout >
    </>
}

export default TaskManager;;
