/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  HTTPValidationError,
  MailDTO,
  MailTemplateDTO,
  MailTemplateInDTO,
} from '../models/index';
import {
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    MailDTOFromJSON,
    MailDTOToJSON,
    MailTemplateDTOFromJSON,
    MailTemplateDTOToJSON,
    MailTemplateInDTOFromJSON,
    MailTemplateInDTOToJSON,
} from '../models/index';

export interface CreateMailTemplateRequest {
    mailTemplateInDTO: MailTemplateInDTO;
}

export interface DeleteMailRequest {
    id: number;
}

export interface DeleteMailTemplateRequest {
    id: number;
}

export interface GetAllMailRequest {
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetAllMailTemplateRequest {
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetAllMailTemplateSentMailsRequest {
    id: number;
    limit?: number;
    offset?: number;
    filter?: string;
}

export interface GetCountMailRequest {
    filter?: string;
}

export interface GetCountMailTemplateRequest {
    filter?: string;
}

export interface GetCountMailTemplateSentMailsRequest {
    id: number;
    filter?: string;
}

export interface GetOneMailRequest {
    id: number;
}

export interface GetOneMailTemplateRequest {
    id: number;
}

export interface SendMailRequest {
    mailAddress: string;
    mailTemplateName: string;
}

export interface UpdateMailTemplateRequest {
    id: number;
    mailTemplateInDTO: MailTemplateInDTO;
}

export interface UpdateMailTemplateSentMailsRequest {
    id: number;
    relationId: number;
    active?: boolean;
}

/**
 * 
 */
export class MailApi extends runtime.BaseAPI {

    /**
     * Create (Mail Template)
     */
    async createMailTemplateRaw(requestParameters: CreateMailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailTemplateDTO>> {
        if (requestParameters['mailTemplateInDTO'] == null) {
            throw new runtime.RequiredError(
                'mailTemplateInDTO',
                'Required parameter "mailTemplateInDTO" was null or undefined when calling createMailTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail_template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailTemplateInDTOToJSON(requestParameters['mailTemplateInDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailTemplateDTOFromJSON(jsonValue));
    }

    /**
     * Create (Mail Template)
     */
    async createMailTemplate(requestParameters: CreateMailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailTemplateDTO> {
        const response = await this.createMailTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete (Mail)
     */
    async deleteMailRaw(requestParameters: DeleteMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteMail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete (Mail)
     */
    async deleteMail(requestParameters: DeleteMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete (Mail Template)
     */
    async deleteMailTemplateRaw(requestParameters: DeleteMailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteMailTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail_template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete (Mail Template)
     */
    async deleteMailTemplate(requestParameters: DeleteMailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.deleteMailTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (Mail)
     */
    async getAllMailRaw(requestParameters: GetAllMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MailDTO>>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MailDTOFromJSON));
    }

    /**
     * Get All (Mail)
     */
    async getAllMail(requestParameters: GetAllMailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MailDTO>> {
        const response = await this.getAllMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (Mail Template)
     */
    async getAllMailTemplateRaw(requestParameters: GetAllMailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MailTemplateDTO>>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail_template/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MailTemplateDTOFromJSON));
    }

    /**
     * Get All (Mail Template)
     */
    async getAllMailTemplate(requestParameters: GetAllMailTemplateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MailTemplateDTO>> {
        const response = await this.getAllMailTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get All (Mail Template.Sent Mails)
     */
    async getAllMailTemplateSentMailsRaw(requestParameters: GetAllMailTemplateSentMailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MailDTO>>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getAllMailTemplateSentMails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail_template/{id}/sent_mails/all`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MailDTOFromJSON));
    }

    /**
     * Get All (Mail Template.Sent Mails)
     */
    async getAllMailTemplateSentMails(requestParameters: GetAllMailTemplateSentMailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MailDTO>> {
        const response = await this.getAllMailTemplateSentMailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (Mail)
     */
    async getCountMailRaw(requestParameters: GetCountMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (Mail)
     */
    async getCountMail(requestParameters: GetCountMailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (Mail Template)
     */
    async getCountMailTemplateRaw(requestParameters: GetCountMailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail_template/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (Mail Template)
     */
    async getCountMailTemplate(requestParameters: GetCountMailTemplateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountMailTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Count (Mail Template.Sent Mails)
     */
    async getCountMailTemplateSentMailsRaw(requestParameters: GetCountMailTemplateSentMailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getCountMailTemplateSentMails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail_template/{id}/sent_mails/count`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Count (Mail Template.Sent Mails)
     */
    async getCountMailTemplateSentMails(requestParameters: GetCountMailTemplateSentMailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getCountMailTemplateSentMailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get One (Mail)
     */
    async getOneMailRaw(requestParameters: GetOneMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOneMail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailDTOFromJSON(jsonValue));
    }

    /**
     * Get One (Mail)
     */
    async getOneMail(requestParameters: GetOneMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailDTO> {
        const response = await this.getOneMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get One (Mail Template)
     */
    async getOneMailTemplateRaw(requestParameters: GetOneMailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailTemplateDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getOneMailTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail_template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailTemplateDTOFromJSON(jsonValue));
    }

    /**
     * Get One (Mail Template)
     */
    async getOneMailTemplate(requestParameters: GetOneMailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailTemplateDTO> {
        const response = await this.getOneMailTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send Mail
     */
    async sendMailRaw(requestParameters: SendMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['mailAddress'] == null) {
            throw new runtime.RequiredError(
                'mailAddress',
                'Required parameter "mailAddress" was null or undefined when calling sendMail().'
            );
        }

        if (requestParameters['mailTemplateName'] == null) {
            throw new runtime.RequiredError(
                'mailTemplateName',
                'Required parameter "mailTemplateName" was null or undefined when calling sendMail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail_template/{mail_template_name}/test/{mail_address}`.replace(`{${"mail_address"}}`, encodeURIComponent(String(requestParameters['mailAddress']))).replace(`{${"mail_template_name"}}`, encodeURIComponent(String(requestParameters['mailTemplateName']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Send Mail
     */
    async sendMail(requestParameters: SendMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.sendMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update (Mail Template)
     */
    async updateMailTemplateRaw(requestParameters: UpdateMailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailTemplateDTO>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateMailTemplate().'
            );
        }

        if (requestParameters['mailTemplateInDTO'] == null) {
            throw new runtime.RequiredError(
                'mailTemplateInDTO',
                'Required parameter "mailTemplateInDTO" was null or undefined when calling updateMailTemplate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail_template/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MailTemplateInDTOToJSON(requestParameters['mailTemplateInDTO']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailTemplateDTOFromJSON(jsonValue));
    }

    /**
     * Update (Mail Template)
     */
    async updateMailTemplate(requestParameters: UpdateMailTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailTemplateDTO> {
        const response = await this.updateMailTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update (Mail Template.Sent Mails)
     */
    async updateMailTemplateSentMailsRaw(requestParameters: UpdateMailTemplateSentMailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateMailTemplateSentMails().'
            );
        }

        if (requestParameters['relationId'] == null) {
            throw new runtime.RequiredError(
                'relationId',
                'Required parameter "relationId" was null or undefined when calling updateMailTemplateSentMails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['active'] != null) {
            queryParameters['active'] = requestParameters['active'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2PasswordBearer", []);
        }

        const response = await this.request({
            path: `/mail/mail_template/{id}/sent_mails/{relation_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"relation_id"}}`, encodeURIComponent(String(requestParameters['relationId']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update (Mail Template.Sent Mails)
     */
    async updateMailTemplateSentMails(requestParameters: UpdateMailTemplateSentMailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.updateMailTemplateSentMailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
