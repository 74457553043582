import { Box, Button, Card, Checkbox, IconButton, Select, Stack, Table, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Children, useEffect, useState } from "react";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CreateIcon from '@mui/icons-material/Create';
import { createContext, useContext } from "react";
import { enqueueSnackbar } from "notistack";

interface CRUDAPIService<T, TIn> {
    get_all: () => Promise<T[]>;
    create: (data: TIn) => Promise<T>;
    update: (data: TIn, id: number) => Promise<T>;
    delete: (data: T) => Promise<T>;
}

interface CRUDTableColumn {
    name: string;
    key: string;
    readOnly?: boolean;
    multiline?: boolean;
}

interface Identifiable {
    id: number;
}

const CRUDTable = <T extends Identifiable & TIn, TIn>({ apiService, columns, title, children, ...props }: { apiService: CRUDAPIService<T, TIn>, columns: CRUDTableColumn[], title: string, children?: React.ReactNode }) => {
    const [items, setItems]: [T[], Function] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updatedModelItem, setUpdateModalItem] = useState<T | undefined>(undefined);

    // Assert that columns are actually in the data type
    if (items.length > 0) {
        const item = items[0];
        columns.forEach((column) => {
            if (!(column.key in item)) {
                throw new Error(`Column key ${column.key} not found in data type`);
            }
        });
    }

    const loadData = async () => {
        const data = await apiService.get_all();
        setItems(data);
    }

    const create = async (data: TIn) => {
        await apiService.create(data).then((response) => {
            setItems([...items, response]);
            enqueueSnackbar(`Created ${title} ${response.id}`, { variant: 'success' });
        });
    }

    const update = async (data: TIn, id: number) => {
        await apiService.update(data, id).then(() => {
            setItems(items.map((d) => d.id === id ? data : d));
            enqueueSnackbar(`Updated ${title} ${id}`, { variant: 'success' });
        })

    }

    const remove = async (data: T) => {
        await apiService.delete(data).then(() => {
            setItems(items.filter((d) => d.id !== data.id));
            enqueueSnackbar(`Deleted ${title} ${data.id}`, { variant: 'success' });
        })
    }

    const createModal = Children.toArray(children).find((child) => (child as any).type === CRUDModalForm && (child as any).props.modes.includes("create"));
    const updateModal = Children.toArray(children).find((child) => (child as any).type === CRUDModalForm && (child as any).props.modes.includes("update"));


    useEffect(() => {
        loadData();
    }, []);

    return <>
        {createModalOpen && <ModalContext.Provider value={{ title, onSubmit: create, handleClose: () => setCreateModalOpen(false), dataTitle: title, data: {} }}>
            {createModal}
        </ModalContext.Provider>}

        {updatedModelItem && updatedModelItem &&
            <ModalContext.Provider value={{ title, onSubmit: (data) => update(data, updatedModelItem.id), handleClose: () => setUpdateModalItem(undefined), data: updatedModelItem, dataTitle: title }}>
                {updateModal}
            </ModalContext.Provider>}

        <Table>
            <TableHead>
                <TableRow>
                    {columns.map((column) =>
                        <TableCell><strong>{column.name}</strong></TableCell>
                    )}
                    <TableCell>
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <strong>Actions</strong>

                            <Button variant="contained" onClick={() => setCreateModalOpen(true)}>Add</Button>
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableHead>
            {items.map((row) =>

                <TableRow>
                    {columns.map((column) =>
                        <TableCell>{(row as any)[column.key]}</TableCell>
                    )}
                    <TableCell>
                        <IconButton onClick={() => remove(row)}>
                            <DeleteForeverIcon />
                        </IconButton>
                        {
                            <IconButton onClick={() => setUpdateModalItem(row)}>
                                <CreateIcon />
                            </IconButton>
                        }
                    </TableCell>
                </TableRow>
            )}
        </Table>
    </>
};

export interface CRUDExtendedAttributeAPIService<T, A> {
    get_all_options: () => Promise<A[]>;
    get_all: (object: T) => Promise<A[]>;
    create: (object_a: T, object_b: A) => Promise<null>;
    delete: (object_a: T, object_b: A) => Promise<null>;
}



export const ExtendedAttribute = function <T extends object, A extends Identifiable>({ apiService, column }: { apiService: CRUDExtendedAttributeAPIService<T, A>, column: CRUDTableColumn }) {
    const original = useContext(ModalContext)["data"] as T;
    const originalTitle = useContext(ModalContext)["dataTitle"];
    const [options, setOptions]: [A[], Function] = useState([]);
    const [data, setData] = useState<A[]>([]);
    const loadData = async () => {
        if (options.length === 0)
            apiService.get_all_options().then((response) => {
                setOptions(response);
            }
            );
        if ("id" in original) {
            apiService.get_all(original).then((response) => {
                setData(response);
            }
            );
        }
    }
    const create = async (addedData: A) => {
        apiService.create(original, addedData).then(() => {
            const newData = [...data, addedData];
            setData(newData);
        });
    }

    const remove = async (removedData: A) => {
        apiService.delete(original, removedData).then(() => {
            const newData = data.filter((d) => d.id !== removedData.id);
            setData(newData);
        });
    }
    useEffect(() => {
        loadData();
    }, [original]);

    return <Stack spacing={1}>
        <Box>
            <Typography>{column.name}</Typography>
            {!("id" in original) &&
                <Typography variant="body2" color="textSecondary">This property can be set after creating the {originalTitle}</Typography>}
        </Box>
        {options.map((option) => (
            <Stack direction="row" alignItems="center" key={option.id}>
                <Checkbox
                    checked={data.some((d) => d.id === option.id)}
                    onChange={(e) => {
                        if (e.target.checked) {
                            create(option);
                        } else {
                            remove(option);
                        }
                    }}
                    disabled={"id" in original ? false : true}
                />
                <label htmlFor={`checkbox-${option.id}`}>{(option as any)[column.key]}</label>
            </Stack>
        ))}
    </Stack>
}

const ModalContext = createContext<{ title: string, onSubmit: (data: any) => void, handleClose: () => void, data: any, dataTitle: string }>({ title: '', onSubmit: () => { }, handleClose: () => { }, dataTitle: '', data: {} });

export const CRUDModalForm = function <T, TIn extends object>({ columns, children, modes }: { columns: CRUDTableColumn[], children?: React.ReactNode, modes: ("create" | "update")[] }) {

    const { title, onSubmit, handleClose, data } = useContext(ModalContext);
    const [formData, setFormData] = useState((data) as TIn);

    const handleChange = (key: string, value: any) => {
        setFormData((prevData: TIn) => ({ ...prevData, [key]: value }));
    };

    const handleSubmit = () => {
        onSubmit(formData);
        handleClose();
    };

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>{"id" in formData ? `Update ${title}` : `Create new ${title}`}</DialogTitle>
            <DialogContent>
                {columns.map((column) => (
                    <TextField
                        key={column.key}
                        label={column.name}
                        fullWidth
                        margin="normal"
                        value={(formData as any)[column.key] || ''}
                        onChange={(e) => handleChange(column.key, e.target.value)}
                        disabled={column.readOnly}
                        multiline={column.multiline}
                    />
                ))}

                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained">Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CRUDTable;
