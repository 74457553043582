import { Box, Card, Grid2, Typography, useTheme } from '@mui/material';
import MainLayout, { MainLayoutSubNavigation, MainLayoutSubNavigationItem } from './main_layout';
import { APIWrapper } from '../../api';
import { useEffect, useState } from 'react';
import { RoleDTO } from '../../api/models/RoleDTO';
import CRUDTable, { CRUDExtendedAttributeAPIService, CRUDModalForm, ExtendedAttribute } from '../../components/crud_table';
import { RoleInDTO } from '../../api/models/RoleInDTO';
import { UserDTO } from '../../api/models/UserDTO';
import { PermissionDTO } from '../../api/models/PermissionDTO';
import { UserInDTO } from '../../api/models/UserInDTO';
import { UserInAdminDTO } from '../../api/models/UserInAdminDTO';
import { MailTemplateDTO } from '../../api/models/MailTemplateDTO';
import { MailTemplateInDTO } from '../../api/models/MailTemplateInDTO';



function Settings() {
    const api = new APIWrapper()
    const usersApi = api.getUsersAPI();
    const mailApi = api.getMailAPI();
    const [page, setPage] = useState("Roles");
    const rolesService = {
        get_all: () => usersApi.getAllRole(),
        create: (data: RoleInDTO) => usersApi.createRole({ roleInDTO: data }),
        update: (data: RoleInDTO, id: number) => usersApi.updateRole({ roleInDTO: data, id: id }),
        delete: (data: RoleDTO) => usersApi.deleteRole({ id: data.id })
    }

    const rolesPermissionService: CRUDExtendedAttributeAPIService<RoleDTO, PermissionDTO> = {
        get_all: (object: RoleDTO) => usersApi.getAllRolePermissions({ id: object.id }),
        get_all_options: () => usersApi.getAllPermission(),
        create: (object_a: RoleDTO, object_b: PermissionDTO) => new Promise((resolve, reject) => null),
        delete: (object_a: RoleDTO, object_b: PermissionDTO) => new Promise((resolve, reject) => null)

    }

    const userService = {
        get_all: () => usersApi.getAllUser({}),
        create: (data: UserInAdminDTO) => usersApi.createUser({ userInAdminDTO: data }),
        delete: (data: UserDTO) => usersApi.deleteUser({ id: data.id }),
        update: (data: UserInAdminDTO, id: number) => usersApi.updateUser({ id: id, userInAdminDTO: data })
    }
        
    const usersRolesService = {
        get_all: (object: UserDTO) => usersApi.getAllUserRoles({ id: object.id }),
        get_all_options: () => usersApi.getAllRole(),
        create: (object_a: UserDTO, object_b: RoleDTO) => new Promise<null>((resolve, reject) => null),
        delete: (object_a: UserDTO, object_b: RoleDTO) => new Promise<null>((resolve, reject) => null)
    }

    const mailTemplateService = {
        get_all: () => mailApi.getAllMailTemplate(),
        create: (data: MailTemplateInDTO) => mailApi.createMailTemplate({ mailTemplateInDTO: data }),
        delete: (data: MailTemplateDTO) => mailApi.deleteMailTemplate({ id: data.id }),
        update: (data: MailTemplateInDTO, id: number) => mailApi.updateMailTemplate({ id: id, mailTemplateInDTO: data })
    }

    return <>
        <MainLayout title="Settings">
            <MainLayoutSubNavigation>
                <MainLayoutSubNavigationItem name={"Roles"} onClick={setPage} />
                <MainLayoutSubNavigationItem name={"Users"} onClick={setPage} />
                <MainLayoutSubNavigationItem name={"Mail Templates"} onClick={setPage} />
            </MainLayoutSubNavigation>
            <Typography> Welcome to the settings page! This is where you are able to find all the settings you need.

            </Typography>
            <Box sx={{ display: page === "Roles" ? "block" : "none" }}>
                <CRUDTable<RoleDTO, RoleInDTO> apiService={rolesService} columns={[{ name: "Name", key: "name" }, { name: "Description", key: "description" }]} title="role">
                    <CRUDModalForm<RoleDTO, RoleInDTO> 
                    columns={[{name: "Id", key: "id", readOnly:true},{ name: "Name", key: "name" }, { name: "Description", key: "description" }]} modes={["create", "update"]}>
                        <ExtendedAttribute column={{ name: "Permissions", key: "name" }} apiService={rolesPermissionService} />
                    </CRUDModalForm>

                </CRUDTable>
            </Box>
            <Box sx={{ display: page === "Users" ? "block" : "none" }}>
                <CRUDTable<UserDTO, UserInAdminDTO> apiService={userService} columns={[{ key: "mailAddress", name: "Mail Address" }]} title="user">
                    <CRUDModalForm<UserDTO, UserInAdminDTO> columns={[{key:"id", name:"id", readOnly: true},{key:"createdAt", name:"Created at", readOnly: true},{ name: "Mail Address", key: "mailAddress" }]} modes={["update","create"]}>
                        <ExtendedAttribute column={{ name: "Roles", key: "name" }} apiService={usersRolesService}/>
                    </CRUDModalForm>
                </CRUDTable>
            </Box>
            <Box sx={{ display: page === "Mail Templates" ? "block" : "none" }}>
            <CRUDTable<MailTemplateDTO, MailTemplateInDTO> apiService={mailTemplateService} columns={[{ key: "name", name: "Name" }, { key: "subject", name: "Subject" }]} title="mail template">
                <CRUDModalForm<MailTemplateDTO, MailTemplateInDTO> columns={[{key:"id", name:"id", readOnly: true},{key:"createdAt", name:"Created at", readOnly: true},{ name: "Name", key: "name" }, { name: "Subject", key: "subject" }, { name: "Mail content", key: "body", multiline: true }]}
                 modes={["update","create"]}>
                </CRUDModalForm>
                </CRUDTable>
                </Box>

        </MainLayout>
    </>
}

export default Settings;;
